import React from "react";
import ReactWOW from "react-wow";
import {OfficeLocation} from "../../../../store/ducks/home/types";
import "./styles.scss";

interface WhereAreWeType {
    title: string
    description?: string
    officesLocations: OfficeLocation[]
}

type Props = WhereAreWeType;

const WhereAreWe = (content:Props) => {

    return <>
        <section id="section-where-are-we">
            <div className="container-site">
                <div className="wrapper-content">
                    <div className="wrapper-text d-flex justify-content-center align-items-center">
                        <div className="text">
                            <ReactWOW animation="fadeInUp" delay="500">
                                <h3>{content.title}</h3>
                            </ReactWOW>
                            <ReactWOW animation="fadeInUp" delay="1000">
                                <p>{content.description}</p>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="wrapper-central-office">
                        <div className="row no-gutters">
                            {content.officesLocations.map((officeLocation, i) => (
                                <div key={i} className="central-office col-md-4">
                                    <div className="central-office-content">
                                        <div className="central-office-image">
                                            <figure>
                                                <ReactWOW animation="fadeIn" delay="750">
                                                    <img src={officeLocation.image} alt={officeLocation.city}/>
                                                </ReactWOW>
                                            </figure>
                                        </div>
                                        <div className="central-office-text">
                                            <ReactWOW animation="fadeInUp" delay={(i * (750)).toString()}>
                                                <h3>{officeLocation.city}</h3>
                                            </ReactWOW>
                                            <ReactWOW animation="fadeInUp" delay={((i + 2) * (750)).toString()}>
                                                <h4>{officeLocation.address}</h4>
                                            </ReactWOW>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>;
};

export default WhereAreWe;