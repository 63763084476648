import React from "react";
import ReactWOW from "react-wow";
import {Client} from "../../../../store/ducks/home/types";
import "./styles.scss";

interface OurClientsType {
    title: string
    description?: string
    clients: Client[]
}

type Props = OurClientsType;

const OurClients = (content:Props) => {

    return <>
        <section id="section-our-clients">
            <div className="wrapper-content">
                <div className="wrapper-text d-flex justify-content-center align-items-center">
                    <div className="text">
                        <ReactWOW animation="fadeInUp" delay="500">
                            <h3>{content.title}</h3>
                        </ReactWOW>
                        <ReactWOW animation="fadeInUp" delay="1000">
                            <p>{content.description}</p>
                        </ReactWOW>
                    </div>
                </div>
                <div className="wrapper-clients clearfix">
                    {content.clients.map((client, i) => (
                      <ReactWOW key={i} animation="fadeIn" delay={((i + 1) * (1000)).toString()}>
                        <img src={client.logo} alt={client.name}/>
                      </ReactWOW>
                    ))}
                </div>
            </div>
        </section>
    </>;
};

export default OurClients;