import React from "react";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {ItemMenuTypes, Menu} from "../../store/ducks/menu/types";
import {Core, CoreTypes} from "../../store/ducks/core/types";
import Logo from "../../assets/images/logo-v2-catskillet.png";
import LogoFooter from "../../assets/images/logo-footer-catskillet.png";
import scrollIt from "../../helpers/helpers";
import "./styles.scss";

const linkLogo:ItemMenuTypes = {
    name: 'Home',
    link: '#section-banner'
};

const dispatchDataCore = () => ({ type: CoreTypes.LOAD_REQUEST});

const Footer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const dataMenu:Menu = useSelector((state: ApplicationState) => state.menu.data);
    const dataCore:Core = useSelector((state: ApplicationState) => state.core.data);
    const handlerToSection = (section:string):void => {
        const sectionElement = document.querySelector(section);
        if(sectionElement) {
            scrollIt(
                sectionElement,
                500,
                'easeOutQuad'
            );
        } else {
            history.push(`/${section}`);
        }
    };

    React.useEffect(() => {
        function getDataCore() {
            dispatch(dispatchDataCore())
        }
        getDataCore();
    }, [dispatch]);

    return <>
        <footer id="footer-main">
            <div className="container-site">
                <div className="row">
                    <div className="col-md-3 justify-content-center align-items-center">
                        <a href={linkLogo.link}
                           title={linkLogo.name}
                           onClick={e => {
                               e.preventDefault();
                               handlerToSection(linkLogo.link)}
                           }
                           className="logo">
                            <img src={Logo} alt="Logo Catskillet"/>
                        </a>
                        <div className="wrapper-text-footer">
                            <p>{dataCore.textFooter}</p>
                        </div>
                    </div>
                    <div className="col-md-3 justify-content-center align-items-center">
                        <nav className="text-center">
                            <ul className="menu-content d-inline-block">
                                {dataMenu.menu.map((item, i) => (
                                    <li key={i}>
                                        <a href={item.link}
                                           title={item.name}
                                           onClick={() => handlerToSection(item.link)}>{item.name}</a>
                                    </li>
                                ))}
                                <li>
                                    <a href="/docs/2.%20Formulário%20dos%20titulares.docx" download target="_blank"
                                       style={{color: "inherit", textTransform: "uppercase"}}>Direito do titular</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-md-3 justify-content-center align-items-center">
                        <div className="contacts">
                            <div className="wrapper-contacts">
                                <div>
                                    <h4>Contact us!</h4>
                                </div>
                                <div>
                                    <a className="email" href={`mailto:${dataCore.contactEmail}`}>{dataCore.contactEmail}</a>
                                </div>
                                <div>
                                    <a className="phone" href={`tel:${dataCore.contactPhone}`}>{dataCore.contactPhone}</a>
                                </div>
                                <div className="wrapper-socials">
                                    {dataCore.socials.map((social, i) => (
                                        <a key={i} href={social.link} target="_blank" rel="noopener noreferrer">
                                            <img src={social.icon} alt={social.name}/>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex justify-content-end align-items-end">
                        <a href={linkLogo.link}
                           title={linkLogo.name}
                           onClick={e => {
                                e.preventDefault();
                                handlerToSection(linkLogo.link)}
                           }
                           className="logo-footer">
                            <img src={LogoFooter} alt="Logo Catskillet"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>{dataCore.copyright}</p>
            </div>
        </footer>
    </>;
};

export default Footer;