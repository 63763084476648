export enum MenuTypes {
    LOAD_REQUEST = '@menu/LOAD_REQUEST',
    LOAD_SUCCESS = '@menu/LOAD_SUCCESS',
    LOAD_FAILURE = '@menu/LOAD_FAILURE'
}

export interface ItemMenuTypes {
    name: string
    link: string
}

export interface Menu {
    menu: ItemMenuTypes[]
}

export interface MenuState {
    readonly data: Menu
    readonly loading: boolean
    readonly error: boolean
}