export enum CoreTypes {
    LOAD_REQUEST = '@core/LOAD_REQUEST',
    LOAD_SUCCESS = '@core/LOAD_SUCCESS',
    LOAD_FAILURE = '@core/LOAD_FAILURE'
}

export interface Socials {
    name: string
    link: string
    icon: string
}

export interface Core {
    textFooter?: string
    copyright: string
    contactEmail: string
    contactPhone: string
    socials: Socials[]
}

export interface CoreState {
    readonly data: Core
    readonly loading: boolean
    readonly error: boolean
}