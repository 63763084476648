import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import { requestSuccess, requestFailure } from "./actions";
import {Client, Home, OfficeLocation, Service} from "./types";
import constants from "../../../config/constants";


function getHome() {
    return api.get(`homepage`);
}

function extractService(services:any):Service[] {
    const data: Service[] = [];

    services.map((service:any) => data.push({
        title: service.title,
        description: service.description,
        icon: `${constants.baseUrlAPI}${service.icon.url}`,
        background: `${constants.baseUrlAPI}${service.background.url}`
    }));

    return data;
}

function extractClients(clients:any):Client[] {
    const data: Client[] = [];

    clients.map((client:any) => data.push({
        name: client.name,
        link: client.link,
        logo: `${constants.baseUrlAPI}${client.logo.url}`,
    }));

    return data;
}

function extractOfficesLocations(officesLocations:any):OfficeLocation[] {
    const data: OfficeLocation[] = [];

    officesLocations.map((officeLocation:any) => data.push({
        city: officeLocation.city,
        address: officeLocation.address,
        image: `${constants.baseUrlAPI}${officeLocation.image.url}`,
    }));

    return data;
}

export default function* loadDataHome () {
    try {
        const response = yield call(getHome);
        const data:Home = {
            title: response.data.title,
            aboutTitle: response.data.aboutTitle,
            aboutDescription: response.data.aboutDescription,
            ourServicesTitle: response.data.ourServicesTitle,
            ourServicesDescription: response.data.ourServicesDescription,
            ourClientsTitle: response.data.ourClientsTitle,
            ourClientsDescription: response.data.ourClientsDescription,
            whereAreWeTitle: response.data.whereAreWeTitle,
            whereAreWeDescription: response.data.whereAreWeDescription,
            services: extractService(response.data.services),
            clients: extractClients(response.data.clients),
            officeLocations: extractOfficesLocations(response.data.office_locations),
        };
        yield put(requestSuccess(data));
    }
    catch (e) {
        yield put(requestFailure());
    }
}