import React from "react";
import ReactWOW from 'react-wow';
import Background from "../../../../assets/images/about-bg.jpg";
import "./styles.scss";

interface AboutType {
    title: string
    description?: string
}

type Props = AboutType;

const About = (content:Props) => {
    return <>
        <section id="section-about" className="h-100">
            <div className="wrapper-content h-100">
                <div className="wrapper-background">
                    <img src={Background} alt="Background Section About" />
                </div>
                <div className="wrapper-text d-flex justify-content-center align-items-center">
                    <div className="text">
                        <ReactWOW animation="fadeInUp" delay="500">
                            <h3>{content.title}</h3>
                        </ReactWOW>
                        <ReactWOW animation="fadeInUp" delay="1000">
                            <p>{content.description}</p>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </section>
    </>;
};

export default About;