import React, { Component } from "react";
import { connect } from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import { ApplicationState } from "../../store";
import * as HomeActions from "../../store/ducks/home/actions";
import { Home } from "../../store/ducks/home/types";
import Banner from "./components/Banner";
import About from "./components/About";
import OurServices from "./components/OurServices";
import OurClients from "./components/OurClients";
import WhereAreWe from "./components/WhereAreWe";

interface StateProps {
    data: Home
}

interface DispatchProps {
    doRequest(): void
}

type Props = StateProps & DispatchProps;

class PageHome extends Component<Props> {

    componentDidMount(): void {
        const { doRequest } = this.props;
        doRequest();
    }

    render() {
        const { data } = this.props;

        return <>
            <Banner />
            <About title={data.aboutTitle} description={data.aboutDescription} />
            <OurServices title={data.ourServicesTitle} description={data.ourServicesDescription} services={data.services} />
            <OurClients title={data.ourClientsTitle} description={data.ourClientsDescription} clients={data.clients} />
            <WhereAreWe title={data.whereAreWeTitle} description={data.whereAreWeDescription} officesLocations={data.officeLocations} />
        </>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    data: state.home.data
});

const mapDispatchToProps =  (dispatch: Dispatch) =>
    bindActionCreators(HomeActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageHome);