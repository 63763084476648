import React, { Component } from "react";
import {Link} from "react-router-dom";
// @ts-ignore
import Rocket from "../../assets/images/rocket.png";
import "./styles.scss";


class NotFoundPage extends Component {

    render() {
        return <>
            <section id="not-found">
                <div className="container-site">
                    <img src={Rocket} alt="Catskillet Rocket"/>
                    <div className="wrapper-text">
                        <h2>404</h2>
                        <p><strong>a página que você procura não está disponível</strong></p>
                        <Link to="/">Voltar para a página principal</Link>
                    </div>
                </div>
            </section>
        </>;
    }
}

export default NotFoundPage;