import React from "react";
import ReactWOW from "react-wow";
import {Service} from "../../../../store/ducks/home/types";
import Vector from "../../../../assets/images/vector.png";
import "./styles.scss";

interface OurServicesType {
    title: string
    description?: string
    services: Service[]
}

type Props = OurServicesType;

const OurServices = (content:Props) => {
    return <>
        <section id="section-our-services">
            <div className="wrapper-content">
                <div className="wrapper-text d-flex justify-content-center align-items-center">
                    <div className="text">
                        <ReactWOW animation="fadeInUp" delay="500">
                            <h3>{content.title}</h3>
                        </ReactWOW>
                        <ReactWOW animation="fadeInUp" delay="1000">
                            <p>{content.description}</p>
                        </ReactWOW>
                    </div>
                </div>
            </div>
            <div className="wrapper-vector">
                <img src={Vector} alt="Vector"/>
            </div>
            <div className="wrapper-content-boxes">
                <div className="row no-gutters">
                    {content.services.map((service, i) => (
                        <div key={i} className="box col-md-4">
                            <div className="box-background">
                                <img className="thumb" src={service.background} alt={service.title}/>
                            </div>
                            <div className="box-content d-flex justify-content-center align-items-center">
                                <div className="box-text">
                                    <div className="box-image">
                                        <ReactWOW animation="fadeIn" delay="500">
                                            <img className="icon" src={service.icon} alt={service.title}/>
                                        </ReactWOW>
                                    </div>
                                    <div className="box-text-content">
                                        <div>
                                            <ReactWOW animation="fadeInUp" delay="1000">
                                                <h3>{service.title}</h3>
                                            </ReactWOW>
                                            <ReactWOW animation="fadeInUp" delay="1500">
                                                <p>{service.description}</p>
                                            </ReactWOW>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>;
};

export default OurServices;