import { Reducer } from "redux";
import { MenuState, MenuTypes } from "./types";

// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
const INITIAL_STATE: MenuState = {
    data: {
        menu: [
            {
                name: 'WHO WE ARE',
                link: '#section-about'
            },
            {
                name: 'WHAT WE DO',
                link: '#section-our-services'
            },
            {
                name: 'OUR PROJECTS',
                link: '#section-our-clients'
            },
            {
                name: 'WHERE WE ARE',
                link: '#section-where-are-we'
            },
        ]
    },
    error: false,
    loading: false
};

const reducer: Reducer<MenuState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MenuTypes.LOAD_REQUEST :
            return {...state, loading: true};
        case MenuTypes.LOAD_SUCCESS :
            return {...state, loading: false, error: false, data: action.payload.data};
        case MenuTypes.LOAD_FAILURE :
            return {...state, loading: false, error: true, data: INITIAL_STATE.data};
        default:
            return state;
    }
};

export default reducer;