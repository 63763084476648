import React from "react";
import LogoBig from "../../../../assets/images/logo-big-catskillet.png";
// @ts-ignore
import VideoLogoMp4 from "../../../../assets/video/logo-home.mp4";
// @ts-ignore
import VideoLogoWebm from "../../../../assets/video/logo-home.webm";
import ArrowDown from "../../../../assets/images/arrow-bottom.png";
import scrollIt from "../../../../helpers/helpers";
import "./styles.scss";

const Banner = () => {
    const [isPaused, setIsPaused] = React.useState(false);
    const handlerToScroll = ():void => {
        scrollIt(
            document.querySelector('#section-about'),
            500,
            'easeOutQuad'
        );
    };
    const handlerPlaying = ():void => {
        const video:HTMLVideoElement | null = document.querySelector('#my-video');
        if(video) {
            setIsPaused(video.paused);
        }
    };
    React.useEffect(() => {
        handlerPlaying();
    }, [isPaused]);
    return <>
        <section id="section-banner">
            <div className="wrapper-content d-flex justify-content-center align-items-center">
                <div className="bg-video"></div>
                <video autoPlay loop preload="auto" muted playsInline id="my-video" onPlaying={handlerPlaying}>
                    <source src={VideoLogoWebm} type="video/webm" />
                    <source src={VideoLogoMp4} type="video/mp4" />
                    <img src={LogoBig} alt="Catskillet" />
                </video>
                {isPaused ? (<img className="image-banner" src={LogoBig} alt="Catskillet" />) : null}
                <div className="scroll-down">
                    <button onClick={handlerToScroll} className="button-scroll-down">
                        <img src={ArrowDown} alt="Scroll Down" />
                    </button>
                </div>
            </div>
        </section>
    </>;
};

export default Banner;