export enum HomeTypes {
    LOAD_REQUEST = '@home/LOAD_REQUEST',
    LOAD_SUCCESS = '@home/LOAD_SUCCESS',
    LOAD_FAILURE = '@home/LOAD_FAILURE'
}

export interface Service {
    title: string
    description: string
    icon: string
    background: string
}

export interface Client {
    name: string
    link?: string
    logo: string
}

export interface OfficeLocation {
    city: string
    address: string
    image: string
}

export interface Home {
    title: string
    aboutTitle: string
    aboutDescription?: string
    ourServicesTitle: string
    ourServicesDescription?: string
    ourClientsTitle: string
    ourClientsDescription?: string
    whereAreWeTitle: string
    whereAreWeDescription?: string
    services: Service[]
    clients: Client[]
    officeLocations: OfficeLocation[]
}

export interface HomeState {
    readonly data: Home
    readonly loading: boolean
    readonly error: boolean
}