import { all, takeLatest } from "redux-saga/effects";
import { HomeTypes } from "./home/types";
import { CoreTypes } from "./core/types";
import loadDataHome from "./home/sagas";
import loadDataCore from "./core/sagas";

export default function* rootSaga () {
    return yield all([
        takeLatest(CoreTypes.LOAD_REQUEST, loadDataCore),
        takeLatest(HomeTypes.LOAD_REQUEST, loadDataHome)
    ])
}