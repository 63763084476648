import React from "react";
import { useHistory } from "react-router-dom";
import ReactWOW from "react-wow";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {ItemMenuTypes, Menu} from "../../store/ducks/menu/types";
import Logo from "../../assets/images/logo-catskillet.png";
import scrollIt from "../../helpers/helpers";
import Hamburger from "./components/Hamburger";
import "./styles.scss";

export interface HeaderTypes {
    menuOpen: boolean
}

const initialState:HeaderTypes = {
    menuOpen: false,
};

const linkLogo:ItemMenuTypes = {
    name: 'Home',
    link: '#section-banner'
};

const Header = () => {
    const history = useHistory();
    const sectionBanner:HTMLElement | null = document.getElementById('section-banner');
    const limitScrollTop = sectionBanner ? (sectionBanner.offsetHeight - 80) : 100;
    const [headerState, setHeaderState] = React.useState<HeaderTypes>(initialState);
    const [currentScroll, setCurrentScroll] = React.useState<number>(0);
    const data:Menu = useSelector((state: ApplicationState) => state.menu.data);

    const handlerScrolling = ():void => {
        window.addEventListener('scroll', () => {
            let nVScroll = document.documentElement.scrollTop || document.body.scrollTop;
            setCurrentScroll(nVScroll);
        });
    };
    const handlerToSection = (section:string):void => {
        const sectionElement = document.querySelector(section);
        if(sectionElement) {
            scrollIt(
                sectionElement,
                500,
                'easeOutQuad'
            );
            setHeaderState({
                ...headerState,
                menuOpen: false
            });
            toggleLockScroll();
        } else {
            history.push(`/${section}`);
        }
    };
    const handleOpenMenu = ():void => {
        setHeaderState({
            ...headerState,
            menuOpen: !headerState.menuOpen
        });
        toggleLockScroll();
    };
    const toggleLockScroll = ():void => {
        const body:HTMLElement | null = document.querySelector('body');
        if(body && body.offsetWidth <= 1024) {
            !headerState.menuOpen && body ? body.classList.add('menu-active') : body.classList.remove('menu-active');
        }
    };

    React.useEffect(handlerScrolling, []);

    return <>
        <header id="header-main" className={currentScroll >= limitScrollTop ? 'active' : ''}>
            <div className="container-site">
                <div className="row">
                    <div className="col-2">
                        <a className={`logo ${currentScroll >= limitScrollTop ? 'visible' : ''}`}
                           href={linkLogo.link}
                           title={linkLogo.name}
                           onClick={e => {
                               e.preventDefault();
                               handlerToSection(linkLogo.link)}
                           }>
                            <h1 id="catskillet">Catskillet</h1>
                            <img src={Logo} alt="Catskillet"/>
                        </a>
                    </div>
                    <div className="col-10">
                        <nav className="d-flex justify-content-end align-items-center h-100">
                            <div className="menu">
                                <Hamburger handleOpenMenu={handleOpenMenu} headerState={headerState} />
                            </div>
                            <ul className={`menu-content ${headerState.menuOpen ? 'active ' : ''}`}>
                                {data.menu.map((item, i) => (
                                    <li key={i}>
                                        <ReactWOW animation="fadeIn" delay="3000">
                                            <a href={item.link}
                                               title={item.name}
                                               onClick={() => handlerToSection(item.link)}>{item.name}</a>
                                        </ReactWOW>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    </>;
};

export default Header;