import React from 'react';
import { Provider } from "react-redux";
import store from "./store";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Header from "./components/Header";
import Footer from "./components/Footer";

import PageHome from "./views/Home";
import NotFoundPage from "./views/NotFound";

const history = createBrowserHistory();
const AfterChangeRoute = () => {
    window.scrollTo(0,0,);
};

// @ts-ignore
const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        // eslint-disable-next-line
        AfterChangeRoute(),
            <Component {...props}/>
    )} />
);


const App = () => <Provider store={store}>
    <Router
        // @ts-ignore
        history={ history }
        // @ts-ignore
        render={ props => getUserAfterChangeRoute()}>
        <Header />
        <Switch>
            <PublicRoute exact path="/" component={ PageHome } />
            <PublicRoute exact path="*" component={ NotFoundPage } />
        </Switch>
        <Footer />
    </Router>

</Provider>;

export default App;