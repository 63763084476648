import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import { requestSuccess, requestFailure } from "./actions";
import constants from "../../../config/constants";
import {Core, Socials} from "./types";


function getCore() {
    return api.get(`core`);
}

function extractSocial(socials:any):Socials[] {
    const data: Socials[] = [];
    socials.map((social:any) => data.push({
        name: social.name,
        link: social.link,
        icon: `${constants.baseUrlAPI}${social.icon.url}`
    }));
    return data;
}

export default function* loadDataCore () {
    try {
        const response = yield call(getCore);
        const data:Core = {
            contactEmail: response.data.contactEmail,
            contactPhone: response.data.contactPhone,
            copyright: response.data.copyright,
            textFooter: response.data.textFooter,
            socials: extractSocial(response.data.socials)
        };
        yield put(requestSuccess(data));
    }
    catch (e) {
        yield put(requestFailure());
    }
}