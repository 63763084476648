import { Reducer } from "redux";
import {CoreState, CoreTypes} from "./types";

const INITIAL_STATE: CoreState = {
    data: {
        textFooter: '',
        copyright: '',
        contactEmail: '',
        contactPhone: '',
        socials: [],
    },
    error: false,
    loading: false
};

const reducer: Reducer<CoreState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CoreTypes.LOAD_REQUEST :
            return {...state, loading: true};
        case CoreTypes.LOAD_SUCCESS :
            return {...state, loading: false, error: false, data: action.payload.data};
        case CoreTypes.LOAD_FAILURE :
            return {...state, loading: false, error: true, data: INITIAL_STATE.data};
        default:
            return state;
    }
};

export default reducer;