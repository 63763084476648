import React from "react";
import { HeaderTypes } from "../../index";
import "./styles.scss";

interface OwProps {
    handleOpenMenu():void
    headerState: HeaderTypes
}

type Props = OwProps;

const Hamburger = ({handleOpenMenu, headerState}:Props) => {

    return (
        <div id="hamburger"
             className={headerState.menuOpen ? 'open' : ''}
             onClick={handleOpenMenu}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default Hamburger;